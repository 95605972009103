import React from 'react'
import Nav from './Nav'
import SocialNav from './SocialNav'
import { headerHolder } from './Layout.module.scss'
import {Title, SubTitle} from './Titles.js'
import {motion} from 'framer-motion'

export default function Header({isHome, isScrolled, isSmallScreen}) {
  const headerVariants = {
    initial: {
      y:"-100%",
    },
    home: {
      y:0,
      scale:1,
      transition: {
        type:'spring',
        delay:3,
        // duration:2,
        mass:1,
        stiffness:600,
        damping:25
      }
    }
  }

  return (
    <>
    { isSmallScreen && <Nav isSmallScreen={isSmallScreen} isScrolled={isScrolled} /> }
    <motion.div variants={headerVariants} initial="initial" animate='home' className={`${headerHolder}`}>
      <Title />
      <SubTitle />
      { !isSmallScreen && <Nav /> }
      <SocialNav isHome={isHome} />
    </motion.div>
    </>
  )
}
