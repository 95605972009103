import { useState, useEffect } from 'react';
import useScrollY from './useScrollY';


export default function useIsScrolled() {
const scrollY = useScrollY()
const [isScrolled, setIsScrolled] = useState(0)

useEffect(()=>{
  setIsScrolled((scrollY > 60))
}, [scrollY, setIsScrolled])

return isScrolled

}