import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faSpotify, faApple, faYoutube , faTwitter} from '@fortawesome/free-brands-svg-icons'

import { socialNavHolder } from './SocialNav.module.scss'

export default function SocialNav({isHome}) {
  return (
    <nav className={`${socialNavHolder} ${isHome ? 'home' : 'notHome'}`}>
      <ul>
        <li><a href="https://www.instagram.com/holliecookie/" title="Instagram" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a></li>
        <li><a href="https://www.facebook.com/holliecookmusic" title="Facebook" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a></li>
        <li><a href="https://twitter.com/holliecookie" title="Twitter" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a></li>
        <li><a href="https://open.spotify.com/artist/1fwuUuFbqXJx3B17PUhFCE?si=eb0329fbbeaa48e1" title="Spotify" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faSpotify} /></a></li>
        <li><a href="https://music.apple.com/us/artist/hollie-cook/420686470" title="Apple Music" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faApple} /></a></li>
        <li><a href="https://www.youtube.com/user/holliecookmusic" title="Youtube" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} /></a></li>
      </ul>
    </nav>
  )
}
