import { useState, useEffect } from 'react';
import useWindowSize from './useWindowSize';

export default function useIsSmallScreen(breakpoint = 700) {
  const size = useWindowSize();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (size.width < breakpoint) setIsSmallScreen(true);

    if (size.width > breakpoint) setIsSmallScreen(false);

    if (size.width / size.height > 1.7 && size.height < 200) setIsSmallScreen(true);
  }, [size, setIsSmallScreen]);

  return isSmallScreen;
}
