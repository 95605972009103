import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { motion } from 'framer-motion'
import { pageElementsHolder,
  leftLeaves,
  rightLeaves,
  pageBackground,
  roomHolder,
  drinksHolder,
  hollieHolder,
sceneHolder,
sceneInner } from './pageElements.module.scss'

const cameraVariants = {
  zoomOut: {
    '--cameraX':['44%', '50%'],
    '--cameraY':['48%', '50%'],
    '--cameraZ':['113px', '0px'],
    transition: {
      type:'tween',
      duration:3,
      ease: [0.76, 0, 0.24, 1]
    }
  },
  zoomedIn: {
    '--cameraZ':'113px',
    '--cameraX':'44%',
    '--cameraY':'48%',
    transition: {
      type:'tween',
      duration:3,
      ease: [0.76, 0, 0.24, 1]
    }
  },
  cameraMove: {
    '--cameraX':['49.5%', '50.5%', '50.5%', '49.5%'],
    '--cameraY':['50.5%', '50.5%', '49.5%', '49.5%'],
    '--cameraZ':['2px', '2px', '2px', '2px'],
    transition: {
      type:'tween',
      duration:4,
      repeat:Infinity,
      repeatType:'mirror'
    }
  }
}

const Hollie = () => {
  return (
    <motion.div className={hollieHolder}>
      <StaticImage src="../../images/pageElements/Hollie and Bar.png"
      alt="Hollie at the bar"
      objectFit="contain"
      className="backgroundImage"
      placeholder="blurred"
      objectPosition="bottom left" />
    </motion.div>
  )
}

const Room = () => {
  return (
    <motion.div className={`${roomHolder}`}>
      <StaticImage src="../../images/pageElements/Room bigger.png"
      alt="Leaves decoration"
      className="backgroundImage"
      placeholder="blurred"
      objectPosition="center" />
    </motion.div>
  )
}

const LeftLeaves = () => {
  return (
    <motion.div className={`${leftLeaves}`}>
      <StaticImage src="../../images/pageElements/Leaves left.png"
      alt="Leaves decoration"
      objectFit="contain"
      objectPosition="bottom left"
      className="backgroundImage"
      placeholder="blurred"
      />
    </motion.div>
  )
}

const RightLeaves = () => {
  return (
    <motion.div className={`${rightLeaves}`}>
      <StaticImage src="../../images/pageElements/Leaves right.png"
      alt="Leaves decoration"
      objectFit="contain"
      className="backgroundImage"
      placeholder="blurred"
      objectPosition="bottom right" />
    </motion.div>
  )
}

const Drinks = () => {
  return (
    <motion.div className={drinksHolder}>
      <StaticImage src="../../images/pageElements/Drinks.png"
      alt="Drinks"
      objectFit="contain"
      className="backgroundImage"
      placeholder="blurred"
      objectPosition="bottom center" />
    </motion.div>
  )
}

const PageBackground = () => {
  return (
    <motion.div className={`${pageBackground}`}>
      <StaticImage src="../../images/pageElements/Background sky.jpg"
      alt="Page background"
      quality={80}  
      className="backgroundImage" 
      placeholder="blurred"
      />
    </motion.div>
  )
}


export default function PageElements({isHome}) {
  return (
    <div className={pageElementsHolder}>
      <PageBackground />
      <motion.div className={sceneHolder} variants={cameraVariants} animate={`${isHome ? 'zoomOut' : 'zoomedIn'}`} >
        <motion.div className={sceneInner} >
          <Room />
          <Hollie />
          <Drinks />
          <RightLeaves />
          <LeftLeaves  />
        </motion.div>
      </motion.div>
    </div>
  )
}
