import React from 'react'
import {motion} from 'framer-motion'
import { siteTitleHolder, subTitleHolder } from './Layout.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import {Link} from 'gatsby'

const hollieVariants = {
  off: {
    opacity:0
    },
  on: {
    opacity:[0,   0,   1,    0,  1,    0,     1,   0,    1, 1],
    transition: {
      times:[0, 0.1, 0.15, 0.2, 0.5, 0.52, 0.54, 0.56, 0.58, 1],
      delay: 1,
      repeat:Infinity,
      repeatDelay: 5
    }
  }
}

const happyHourVariants = {
  off: {
    opacity:0
    },
  on: {
    opacity:[0,   0,   1,    0,  1,    0,     1,   0,    1, 1],
    transition: {
      times:[0, 0.1, 0.15, 0.2, 0.5, 0.52, 0.54, 0.56, 0.58, 1],
      delay: 1.5,
      repeat:Infinity,
      repeatDelay: 6
    }
  }
}

export function Title() {
  return (
    <motion.div className={siteTitleHolder}>
      <h1>Hollie Cook</h1>
      
      <motion.div className="unlit">
          <StaticImage src="../../images/logo/hollie-cook-logo-2-unlit.png"
          alt="Hollie Cook Logo unlit"
          placeholder="blurred"
          />
      </motion.div>

      <motion.div className="lit" initial='off' animate='on' variants={hollieVariants}>  
        <Link to="/" title='Home'>
          <StaticImage  src="../../images/logo/hollie-cook-logo-2-lit.png"
          alt="Hollie Cook Logo lit"
          placeholder="blurred"
          />
        </Link>
      </motion.div>    

    </motion.div>
  )
}


export function SubTitle() {
  return (
    <motion.div className={subTitleHolder}>
      <h1>Hollie Cook</h1>
      
      <motion.div className="unlit">
          <StaticImage src="../../images/logo/happy-hour-unlit.png"
          alt="Hollie Cook Logo unlit"
          placeholder="blurred"
          />
      </motion.div>

      <motion.div className="lit" initial='off' animate='on' variants={happyHourVariants}>  
        <Link to="/" title='Home'>
          <StaticImage  src="../../images/logo/happy-hour-lit.png"
          alt="Hollie Cook Logo lit"
          placeholder="blurred"
          />
        </Link>
      </motion.div>     

    </motion.div>
  )
}