import { useState, useEffect } from 'react';

export default function useScrollY() {
  const [scrollY, setScrollY] = useState(0)
  
  useEffect(()=> {

    function scrolled(e) {
      setScrollY(window.scrollY)
    }

    if (typeof window !== undefined) {
      setScrollY(window.scrollY)

      window.addEventListener('scroll', scrolled)

    return () => window.removeEventListener('scroll', scrolled)
    }
  },[])

  return scrollY
}