import React, { useEffect, useState} from 'react'

import { Link } from 'gatsby'

import '../../styles/hamburgers.min.css'
import {navHolder} from './Nav.module.scss'

import {AnimatePresence, motion} from 'framer-motion'

export default function MainNav({isSmallScreen}) {
  const [navOpen, setNavOpen] = useState(false)

  useEffect(()=>{
    setNavOpen(!isSmallScreen)
  },[isSmallScreen, setNavOpen])

  const variants = {
    pre: {
      opacity: 0
    },
    visible: {
      opacity:1
    },
    post: {
      opacity:0
    }
  }


  return (
    <div className={navHolder}>
        { isSmallScreen &&
        <div className="burger">
          <button
            className={`hamburger hamburger--slider
            ${navOpen ? 'is-active' : ''}`}
            type="button"
            onClick={()=>setNavOpen(!navOpen)}
            >
            <span className="hamburger-box">
              <span className="hamburger-inner">Toggle Nav</span>
            </span>
          </button>
        </div>
        }

        <AnimatePresence>
        {
        (!isSmallScreen || navOpen) && 
          <motion.ul 
            variants={variants}
            initial="pre"
            animate="visible"
            exit="post"
            key="linksHolder"
            className={`linksHolder ${isSmallScreen && 'smallScreenNavHolder'}`}
            onClick={()=>setNavOpen(false)}
            // role="button"
            onKeyDown={(e)=> {
              if (e.code==='Escape')
              setNavOpen(false)
            }}
            >
              <li><Link to="/music" title="Music" activeClassName="active">Music</Link></li>
              <li><a href="https://store.holliecook.com" title="Store" >Store</a></li>
              <li><Link to="/live" title="Live" activeClassName="active">Live</Link></li>
              {/* <li><Link to="/videos" title="Videos" activeClassName="active">Videos</Link></li> */}
              <li><Link to="/contact" title="Contact" activeClassName="active">Contact</Link></li>
          </motion.ul>
        }
        </AnimatePresence>
    </div>
  )
}
