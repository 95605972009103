import React, {useState, useEffect}from "react"

import "../../styles/normalize.css"
import "../../styles/styles.scss"
import { mainHolder } from './Layout.module.scss'

import Header from "./Header"
import Footer from "./Footer"
import PageElements from './PageElements'

import useIsScrolled from '../../hooks/useIsScrolled'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'
import { AnimatePresence, motion } from "framer-motion"

import {isBrowser} from '../../utils/helperFunctions'


const mainVariant = {
  i: {
    opacity:0,
    y:'3rem'
  },
  v: {
    opacity:1,
    y:0,
    transition: {
      type:"tween",
      duration:0.5,
      delay:0.6
    }
  },
  o: {
    opacity:0,
    y:'-3rem',
    transition: {
      type: "tween",
      duration:0.4
    }
  }
}

const Layout = ({ children, location, ...props}) => {
  const [isHome, setIsHome] = useState(children?.props?.path==='/')
  const isSmallScreen = useIsSmallScreen()
  const isScrolled = useIsScrolled()

  //set ishome
  useEffect(()=>{
    setIsHome(children?.props?.path==='/')
  }, [children])


  const updateScroll = ()=>{
    if(isBrowser()) {
      window.scrollTo(0,0)
    }
  }


  return (
    <div className={mainHolder}>

        <Header isHome={isHome} isScrolled={isScrolled} isSmallScreen={isSmallScreen} />
        <AnimatePresence mode="wait" onExitComplete={()=>updateScroll()}>
          <motion.main key={children?.props?.path} variants={mainVariant} initial="i" animate="v" exit="o">
            {children}
          </motion.main>
        </AnimatePresence>
      <PageElements isHome={isHome} />
      <Footer />

    </div>
  )
}

export default Layout
