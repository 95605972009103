import React from 'react'
import { footerHolder } from './Layout.module.scss'

export default function Footer() {
  return (
    <footer className={footerHolder}>
      <div className="footerInner">
        Site created by <a href="mailto:ben@lookupstudios.co.uk" alt="Look Up Studios">Look Up Studios</a>
      </div>
    </footer>
  )
}



